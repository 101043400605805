<template>
  <div class="Template Template--map">
    <ux-atoms-link href="#main" skip>{{ $t('layout.skipToMain') }}</ux-atoms-link>
    <ux-molecules-main-nav v-if="!isWebView" />
    <main class="Main position-relative" id="main" role="main">
      <slot />
    </main>
  </div>
</template>

<script lang="ts" setup>
const { isWebView } = useWebView();
</script>
